$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}













































































.cookie-banner-panel {
  background-color: $main-light-color;
  max-width: 1200px;
  max-height: 100%;
  margin: auto;
  overflow-y: auto;
  position: relative;

  &__close-cta {
    text-align: right;
    padding: 1rem;
    font-weight: 700;

    &:hover {
      cursor: pointer;
      color: $main-light-color;
      background-color: var(--second-option-color);
    }
  }

  &__title {
    text-align: center;
    color: var(--second-option-color);
    font-weight: 700;
    font-size: $fs-title;
    margin: 1rem auto;
  }

  &__subtitle {
    max-width: 900px;
    text-align: center;
    color: $main-dark-color;
    font-size: $fs-text;
    margin: 1rem auto;
  }
}

.cookie-banner-panel-settings {
  background-color: var(--main-option-color);
  color: white;

  &__card {
    padding: 2rem;
    border-bottom: solid $main-medium-color 1px;
  }

  &__title {
    font-weight: 700;
    font-size: 1.2rem;
  }

  &__cta {
    background-color: $main-light-color;
    color: $main-dark-color;
    border-radius: $border-radius;
    padding: 0.4rem 1rem;
    min-width: 8rem;
    text-align: center;

    &--refused-active {
      background-color: red;
      color: $main-light-color;
    }

    &--authorised-active {
      background-color: green;
      color: $main-light-color;
    }

    @media (min-width: $breakpoint-large) {
      margin-left: 0.4rem;
    }
  }

  &__content {
    @media (min-width: $breakpoint-large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
