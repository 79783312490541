@keyframes circle-int-animation {
  from {
    background-color: #ffffffb7;
    border: solid #ffffffb7 0px;
  }
  30% {
    background-color: #ffffff7d;
    border: solid #ffffff7d 10px;
  }
  to {
    background-color: #ffffffb7;
    border: solid #ffffffb7 0px;
  }
}

@keyframes reveallight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInFadeOut {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes revealSlidelight {
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes revealSlide {
  0% {
    transform: translatex(-300px);
  }
  100% {
    transform: translatex(Opx);
  }
}

@keyframes revealSlideUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes revealSlide {
  0% {
    transform: translatex(-300px);
  }
  100% {
    transform: translatex(Opx);
  }
}

@keyframes revealSlideToRightLight {
  0% {
    transform: translatex(-150px);
  }
  100% {
    transform: translatex(Opx);
  }
}

@keyframes revealSlideToLeftLight {
  0% {
    transform: translatex(150px);
  }
  100% {
    transform: translatex(Opx);
  }
}

@keyframes lineMove {
  from {
    opacity: 0;
    width: 40%;
  }
  to {
    width: 80%;
  }
}
