$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}






























































































































































































































































































.dashboard {
  padding: min(2rem, 6%);
  background-color: $main-light-color;
  animation: reveallight 600ms;

  &__title {
    @extend %ext-title;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 0 3rem 3rem;
  }
}

.cards-container {
  @media (min-width: $breakpoint-large) {
    display: flex;
    width: 100%;
  }
}

.dashboard-card {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;
  margin-bottom: 1rem;

  &__title {
    @extend %ext-subTitle;
  }
}
.dashboard-card-program {
  &__img {
    width: auto;
    height: 15.5rem;
    object-fit: contain;
  }

  &__cta {
    font-size: $fs-link;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  &__cta-image {
    height: 0.9rem;
    width: auto;
    padding-left: 1rem;
  }
}

.dashboard-card-wrapper-2 {
  width: 100%;

  section {
    width: 100%;
    @media (min-width: $breakpoint-medium) {
      width: 49%;
    }
  }

  @media (min-width: $breakpoint-medium) {
    display: flex;
    justify-content: space-between;
  }
}
.dashboard-card-notifications-content {
  &__notifications {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a {
      margin: 1px;
      width: 100%;
    }
  }

  &__lastContent {
    @media (min-width: $breakpoint-large) {
      display: flex;
      margin: 1px;
      justify-content: space-between;
      width: 100%;
    }
    a {
      width: 100%;
    }
  }
}

.dashboard-card-lot {
  &__tab {
    display: inline-block;
    border: solid $border-color 1px;
    border-radius: $border-radius $border-radius 0 0;
    min-width: 5rem;
    padding: 0.3rem 1rem;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    color: var(--second-option-color);

    &--disabled {
      opacity: 0.6;
      font-weight: 500;
      background-color: $bg-color;
    }
  }

  &__content {
    padding: 1rem;
    background-color: $bg-color;
  }

  &__boxes {
    display: flex;

    &-item {
      display: inline-block;
      background-color: $main-light-color;
      border: solid $main-medium-color 1px;
      padding: 2px 0.5rem;
      margin-right: 2px;
      margin-bottom: 4px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
  }

  &__gallery {
    margin-top: 0.4rem;
    width: auto;
    height: 13.2rem;
    object-fit: contain;
  }
}
