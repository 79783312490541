$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}



















































.user-identification {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-position: center;
  background-size: cover;

  @media (min-width: $breakpoint-small) {
    background-image: radial-gradient(circle, #e0e0e0, #ffffff);
  }

  &-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-size;
    width: 100%;
    text-align: center;
    padding: 1.4rem;

    &__logo {
      height: 4.25rem;
      width: auto;
      max-width: 50%;
      max-height: 3.75rem;
    }

    &__return {
      border-radius: 50%;
      background-color: $transparent-medium-color;
      width: 4.25rem;
      height: 4.25rem;
      padding: 1.2rem;
      transition: box-shadow 200ms, background-color 200ms;

      &:hover {
        background-color: $main-light-color;
      }
    }
  }
}

.recoveryContainer,
.signInContainer,
.loginContainer {
  padding: 2.3rem 2.7rem;
  margin: auto;
  background-color: $main-light-color;
  animation: revealSlidelight 300ms;
  border-radius: $border-radius;

  @media (min-width: $breakpoint-small) {
    margin: auto;
  }
  @media (max-width: $breakpoint-xs) {
    padding: 10%;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }

  &__logo {
    width: 50%;
    max-width: 50%;
    max-height: 3.75rem;
    text-align: center;
    border: solid black 1px;
  }
}

.recoveryForm,
.signInForm,
.loginForm {
  padding-top: 0.4rem;
  max-width: 25rem;
  margin: auto;

  @media (min-width: $breakpoint-small) {
    min-width: 18.75rem;
  }
}

.recoveryForm__field,
.signInForm__field,
.loginForm__field {
  position: relative;
  margin-top: 1.6rem;
  flex-direction: column;

  label {
    position: absolute;
    top: -1rem;
    color: $main-dark-color;
    font-weight: 700;
    text-transform: uppercase;
    font-size: $fs-text;
    animation: revealSlidelight 300ms;
  }

  input {
    border: none;
    border-bottom: 1px solid #b3b3b3;
    color: $main-dark-color;
    font-size: $fs-text;
    font-weight: 300;
    width: 100%;
    padding: 2px 0;
  }

  input::placeholder {
    border: 0;
    font-weight: lighter;
  }

  input:focus {
    border-bottom: 1px solid $main-dark-color;
  }
}

.togglePassword {
  position: absolute;
  right: 0;
}

.recoveryForm__link {
  text-align: center;
}

.recoveryForm__link,
.loginForm__recoveryLink,
.loginForm__field--checkbox label {
  font-size: $fs-link;
}

.recoveryForm__link,
.loginForm__recoveryLink,
.loginForm__field--checkbox {
  margin-top: 0.9rem;
}

.recoveryForm__link,
.loginForm__recoveryLink {
  text-decoration: underline;
  color:  var(--main-option-color);
  cursor: pointer;

  &:hover {
    color: var(--second-option-color);
    cursor: pointer;
  }
}

.signInSucces {
  height: auto;
  display: flex;
  padding: 4rem;

  p {
    margin: auto;
    font-weight: 500;
    text-transform: uppercase;
    color: $main-dark-color;
    letter-spacing: -0.04rem;
  }
}

.loginForm__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recoveryForm__subText,
.loginForm__subText {
  text-align: center;
  font-size: $fs-text;
  padding: 0.5rem 0;
  color: $main-dark-color;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 300ms;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
