$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}

































































































.cookie-banner-container {
  position: fixed;
  background-color: $modal-bg-color;
  z-index: $z-index-modal;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
}

.cookie-banner {
  z-index: $z-index-modal;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 80%;
  background-color: $main-light-color;
  padding: 2rem 1.6rem;
  overflow-y: auto;
  animation: revealSlideUp 800ms;

  &__title {
    color: var(--main-option-color);
    font-weight: 700;
    font-size: $fs-title;
    margin-bottom: 1rem;
  }

  &__text {
    color: $main-dark-color;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  &__cta {
    min-width: 100%;
    margin-bottom: 1rem;
    font-size: 0.8rem;
  }

  @media (min-width: $breakpoint-small) {
    &__cta-wrapper {
      display: flex;
      justify-content: center;
    }

    &__cta {
      min-width: 10rem;
      width: auto;
      margin-bottom: 1rem;
    }
  }

  @media (min-width: $breakpoint-large) {
    width: 100%;
    margin: auto;

    &__content-wrapper {
      display: flex;
      justify-content: center;
      margin: auto;
    }

    &__title {
      margin: 0 auto 1rem auto;
    }

    &__title,
    &__content-wrapper {
      max-width: 1200px;
    }

    &__text {
      padding-right: 2rem;
    }

    &__cta-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__cta {
      min-width: 10rem;
      width: 14rem;
      margin-bottom: 1rem;
    }
  }
}
