.error-page {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  max-width: 80%;
  margin: auto;

  @media (min-width: $breakpoint-medium) {
    max-width: 60%;
  }

  &__container {
    margin: auto;
    padding: 2rem;
    background-image: url("/icon/square.svg");
    background-repeat: no-repeat;
    background-size: 20%;
    @media (min-width: $breakpoint-medium) {
      background-size: 7.5rem;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    animation: reveallight 1000ms, revealSlideToRightLight 600ms;
    @media (min-width: $breakpoint-medium) {
      font-size: 2rem;
    }
  }
  &__link {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: $main-dark-color;
    background-color: $main-light-color;
    border-radius: $border-radius;
    border: solid $main-dark-color 1px;
    padding: 0.6rem 2rem;
    animation: reveallight 1200ms;

    &:hover {
      color: $main-light-color;
      background-color: $main-dark-color;
    }
  }
}
