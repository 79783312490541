$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}































































































































































































































































































































































.apptContainer {
  background-color: white;
  opacity: 0.94;
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  @media (max-height: 600px) {
    opacity: 1;
  }

  &__footer {
    margin-top: auto;
  }
}

.appointment,
.appointment-success {
  color: $main-dark-color;
  background-color: #ffffff;
  margin: auto;
  padding: 2rem 8vw 0 8vw;
  font-size: $fs-text;

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 1rem;
  }

  &__subtitle {
    display: block;
    margin-bottom: 22px;
    font-weight: 900;
    text-transform: uppercase;
  }

  &__text {
    font-size: 1rem;
    color: $alert-color;
    font-weight: bold;
  }
}

.infos__title,
.infos__text {
  padding-bottom: 1rem;
}

.btnSelection {
  border: solid $main-dark-color 1px;
  color: $main-dark-color;
  padding: 0.4em;
  width: calc(100vw / 8);
  text-align: center;
  background-color: $main-light-color;
}

.btnSelected {
  background-color: $main-dark-color;
  color: $main-light-color;
  font-weight: bold;
}

.validation {
  text-align: center;
}

.timeSlot {
  width: 33.33%;
  height: 10vh;

  &-active {
    display: flex;
    background-color: $main-light-color;
    > div {
      margin: auto;
      text-align: center;
    }
  }

  &-inactive {
    background-color: #f0f0f0;
    opacity: 0.8;
    pointer-events: none;
    cursor: default;
    display: flex;
    > div {
      margin: auto;
      width: 100%;
    }
  }

  & > * {
    font-size: 0.8rem;
    width: 2.2rem;
    line-height: 2.2rem;
    text-align: center;
    border-radius: 50%;
  }
}

.calendar {
  min-height: 100%;
  overflow: auto;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    min-height: 100%;
    margin: 1.2rem auto;
  }

  &__days,
  &__hours {
    width: 100%;

    @media (min-width: $breakpoint-medium) {
      width: 49%;
    }
  }

  &__days {
    font-weight: 700;
  }

  &__hours {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    min-height: 300px;
    margin-bottom: 7px;
    border: solid rgb(237, 237, 237) 1px;
  }

  @media screen and (max-width: 890px) {
    margin: 4px auto;
  }
}

.daysBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  margin: 0.5rem auto;
  background-color: rgb(242, 242, 242);

  &__name {
    background-color: $main-light-color;
    width: calc(100% / 7);
    padding: 2px;
    text-align: center;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 900;

    &:nth-child(6n),
    &:nth-child(7n) {
      color: var(--second-option-color);
    }
  }

  &__day {
    width: calc(100% / 7);
    height: 10vh;
    text-align: left;
    display: flex;

    & > div {
      margin: 2px;
      font-size: 0.9rem;
      width: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 50%;
      margin: auto;
    }

    &:nth-child(7n) {
      border-right: solid $main-medium-color 1px;
    }

    &:hover > div {
      background-color: var(--main-option-color);
      color: $main-light-color;
      font-weight: bold;
    }
  }

  &:first-child() {
    color: red;
  }
}

.daySelection {
  background-color: $main-light-color;
  color: $main-dark-color;
  font-weight: 900;
  text-align: left;
  font-size: 1.2rem;
  padding: 0.4em 0;
  min-width: 180px;
  text-transform: uppercase;

  @media screen and (max-width: 890px) {
    font-size: 1rem;
  }
}

.timeSlot-active:hover > div,
.btnSelection:hover > div,
.days:hover > div {
  cursor: pointer;
  background-color: var(--main-option-color);
  color: $main-light-color;
  font-weight: bold;
}

.selectedDay > div,
.selectedHour > div {
  cursor: pointer;
  background-color: var(--second-option-color);
  color: $main-light-color;
  font-weight: bold;
}

.appointmentDateSelected {
  color: var(--second-option-color);
  font-weight: bold;
}

.error {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  &__text {
    margin: auto;
  }
}

.appointment__title,
.appointment__contactForm,
.appointment__text,
.appointment__subtitle,
.date {
  animation: revealSlidelight 400ms;
}

.btnReturn {
  margin-right: 1rem;
}

.appointment__confirmation {
  position: absolute;
  top: 0;
  bottom: 0;
}

.appointment__loader {
  margin-top: auto;
  margin-bottom: auto;
}
