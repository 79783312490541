$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}














































































































































































































































































































































.message-container {
  @media (min-width: $breakpoint-large) {
    width: 70%;
  }
}

.messages {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  @media (min-width: $breakpoint-large) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3rem 3rem;
  }

  &__box {
    margin-bottom: 2rem;
    border-radius: $border-radius;
    overflow-y: hidden;
    border: solid $border-color 1px;
    border-radius: $border-radius;

    @media (min-width: $breakpoint-medium) {
      overflow-y: auto;
      max-height: 12.5rem;
    }

    &--empty {
      padding: 0.4rem 0.6rem;
    }
  }

  &__title {
    @extend %ext-title;
  }

  &__subtitle {
    @extend %ext-subTitle;
    color: var(--second-option-color);
    padding-bottom: 1rem;
  }

  &__text {
    @extend %ext-text;
  }

  &__return {
    margin-bottom: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    border: solid black 1px;
    border-radius: 50%;
    transition: background-color 200ms;

    img {
      margin-left: 0.5rem;
      width: 1rem;
      height: auto;
    }

    &:hover {
      background-color: $bg-color;
    }
  }

  &__contact {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 1rem;
  }
}

.message-item {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 0.4rem 0;
  color: $main-medium-color;
  font-weight: 500;
  border-top: solid $border-color 1px;

  &:first-child {
    border-top: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $bg-color;
    color: $main-dark-color;
  }

  &__date {
    max-width: 50%;
    width: 12.5rem;
    font-size: 0.9rem;
  }

  &__notification::before {
    content: " ";
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--main-option-color);
  }

  &__heading {
    max-width: 50%;
  }

  &__date,
  &__heading {
    padding-right: 2rem;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--unread {
    color: $main-dark-color;
    font-weight: 600;
  }
}

.lot-selector {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &__label {
    margin-right: 0.4rem;
  }

  &__select {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 0 0.6rem;
    text-transform: uppercase;

    @media (hover: hover) {
      &:hover {
        background-color: $bg-color;
        cursor: pointer;
      }
    }
  }
}

.contact {
  &__heading,
  &__textarea {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: solid $border-color 1px;
    border-radius: $border-radius;
  }

  &__heading {
    font-weight: 500;
    display: flex;
  }

  &__label {
    margin-right: 2rem;
  }

  &__textarea {
    border: solid $border-color 1px;
    width: 100%;
  }
}

.conversation {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  font-size: $fs-text;
  overflow: auto;
  height: 60%;

  &__heading,
  &__content {
    padding: 0.5rem 1rem;
  }

  &__heading {
    background-color: $bg-color;
    font-weight: 600;
    display: flex;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
  }

  &__text {
    margin: 1rem auto;
    padding-top: 1rem;
    border-top: solid $bg-color 1px;
  }

  &__timestamp,
  &__contact {
    padding: 0;
    margin: 0;
  }

  &__contact,
  &__subject {
    margin-bottom: 0.5rem;
  }
}

.conversation-msg {
  margin: 1rem 0;

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    p {
      font-size: $fs-small;
      font-weight: 600;
    }

    p:last-child {
      font-weight: 300;
    }

    &--right {
      flex-direction: row-reverse;
    }
  }

  &__icon {
    background-color: $bg-color;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    border-radius: 50%;

    &--right {
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  &__content {
    font-size: $fs-small;
    background-color: $bg-color;
    border-radius: 1.2rem;
    padding: 0.8rem;
    display: inline-block;

    &--right {
      background-color: $msg-color;
    }
  }

  &--right {
    text-align: right;
  }
}
