$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}


















































































.swipper-fullscreen-container {
  z-index: $z-index-modal;
  background-color: $modal-bg-color;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  animation: reveallight 400ms;
}

.carousel-wrapper-fullscreen {
  width: 100%;
  height: auto;
  margin: auto;

  @media (min-width: $breakpoint-large) {
    width: 80%;
    height: auto;
    margin: auto;
  }
}

.swiper {
  height: 100%;
}

.swiper-slide {
  margin: auto;
  &:hover {
    cursor: pointer;
  }
}

.swiper-close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: background-color 200ms, border 200ms;
  background-color: $transparent-light-color !important;

  &:hover {
    background-color: $transparent-medium-color !important;
  }

  @media (max-width: $breakpoint-small) {
    display: none;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.8rem;
}
