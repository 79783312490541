$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}

















































































.theHeader {
  background-color: $bg-color;
  display: block;
  text-align: center;
  padding: 1.4rem;

  @media (min-width: $breakpoint-large) {
    height: $header-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: none;
    z-index: $z-index-navigation;

    @media (min-width: $breakpoint-large) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: $fs-main-title;
    margin: 1rem auto 0;

    @media (min-width: $breakpoint-large) {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 1.5rem;
      margin: auto;
    }

    &--bold {
      font-weight: 700;
      font-size: $fs-main-title;
    }
  }

  &__logo {
    max-width: 50%;
    max-height: 3.75rem;

    @media (min-width: $breakpoint-large) {
      width: auto;
      height: 4.25rem;
    }
  }
}

.menu {
  &__link {
    font-size: $fs-link;
    font-weight: 500;
    text-align: left;
    margin-left: 1.6rem;

    &:last-child {
      margin-left: 3.2rem;
    }
  }

  &__user {
    font-size: $fs-link;
    font-weight: 600;
    text-align: left;
    margin-left: 1.6rem;
  }

  &__logout {
    font-weight: 300;
  }

  &__link,
  &__logout,
  &__userinfos {
    transition: text-decoration 200ms, color 200ms;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: var(--second-option-color);
    }
  }

  &__btn {
    border-radius: 50%;
    background-color: $btn-main-color;
    box-shadow: 0px 0px 14px 4px #e0e0e0;
    width: 4.25rem;
    height: 4.25rem;
    padding: 1.2rem;
    transition: box-shadow 200ms, background-color 200ms;

    &--active {
      &:hover {
        box-shadow: 0px 0px 14px 2px grey;
      }
    }
    &--disabled {
      opacity: 0.5;
      &:hover {
        box-shadow: 0;
      }
    }
  }
}
