$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}






































.error-page {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  max-width: 80%;
  margin: auto;

  @media (min-width: $breakpoint-medium) {
    max-width: 60%;
  }

  &__container {
    margin: auto;
    background-image: url("/icon/square.svg");
    background-repeat: no-repeat;
    background-size: 20%;
    min-width: 80%;
    padding: 1rem;

    @media (min-width: $breakpoint-medium) {
      background-size: 7.5rem;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    animation: reveallight 1000ms, revealSlideToRightLight 600ms;

    @media (min-width: $breakpoint-medium) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    font-family: "Segoe UI", Verdana, sans-serif;
    color: $main-medium-color;
    animation: reveallight 400ms;
  }

  &__link {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: $main-dark-color;
    background-color: $main-light-color;
    border-radius: $border-radius;
    border: solid $main-dark-color 1px;
    padding: 0.6rem 2rem;
    animation: reveallight 1200ms;

    &:hover {
      color: $main-light-color;
      background-color: $main-dark-color;
    }
  }
}
