$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}










































































































































.project-card {
  border: solid $border-color 1px;
  padding: 1.4rem;
  border-radius: $border-radius;
  width: 92%;
  margin: auto;
  background-color: $bg-color;

  @media (min-width: $breakpoint-small) {
    margin: 0;
    width: 22rem;
  }

  &__date,
  &__lot,
  &__infos,
  &__desc p,
  &__heading {
    font-size: 0.8rem;
    color: $main-medium-color;
  }

  &__lot,
  &__heading {
    text-transform: uppercase;
  }

  &__heading--bold {
    font-weight: 700;
  }

  &__infos,
  &__desc {
    margin-bottom: 0.5rem;
  }

  &__infos {
    display: flex;
    justify-content: space-between;
  }

  &__lot {
    font-weight: 700;
    color: $main-dark-color;
  }

  &__desc,
  &__img {
    margin-bottom: 0.5rem;
  }

  &__desc {
    display: flex;
    flex-wrap: wrap;

    p {
      padding-right: 4px;
    }
  }

  &__img {
    width: 100%;
    height: 9rem;
    margin: 0.4rem auto 0.7rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__cta {
    font-size: 0.7rem;
    border: solid black 1px;
    border-radius: $border-radius;
    padding: 0.3rem 0.4rem;
    background-color: var(--main-option-color);
    color: $main-light-color;
    text-transform: uppercase;
    font-weight: 700;
    transition: background-color 200ms, border 200ms, color 200ms;

    &--ghost {
      background-color: $bg-color;
      color: $main-dark-color;
      &:hover {
        background-color: var(--second-option-color);
        border: solid var(--second-option-color) 1px;
        color: $main-light-color;
        cursor: pointer;
      }
    }

    &--disabled {
      background-color: $bg-color;
      color: $main-dark-color;

      :hover {
        background-color: none;
        border: solid none 1px;
        color: $main-light-color;
        cursor: default;
      }
    }

    img {
      width: 1rem;
    }
  }
}
