$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}






















































.visit-link {
  background-position: center;
  background-size: cover;
  font-weight: 700;
  margin: 0;
  border-radius: 0;

  &--cta {
    padding: 0.8em 3em;
    background-color: var(--main-option-color);
    color: $main-light-color;
    border-radius: $border-radius;
    letter-spacing: 1px;
    font-size: 2vmin;
    text-transform: uppercase;
    transition: background-color 300ms;
    margin-bottom: 4%;

    @media (max-width: $breakpoint-large) {
      font-size: 3vmin;
    }

    &:hover {
      background-color: var(--second-option-color);
    }
  }
}

.circle-int {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50vmin;
  height: 50vmin;
  min-width: 13.75rem;
  min-height: 13.75rem;
  background-color: #ffffffb7;
  color: $main-dark-color;
  border: solid #ffffffb7 0px;
  transition: background-color 200ms 50ms, box-shadow 200ms 50ms,
    transform 400ms;

  &:hover {
    cursor: pointer;
    background-color: $transparent-medium-color;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
  }

  &__title,
  &__city,
  &__text {
    text-transform: uppercase;
  }

  &__title {
    font-size: 3.2vmin;
    font-weight: 900;
  }

  &__city {
    font-size: 2.6vmin;
    font-weight: 300;
    letter-spacing: 1px;
  }

  &__text {
    font-size: 2.6vmin;
    font-weight: 300;
  }

  &__img {
    margin-top: 4%;
    height: auto;
    max-height: 10vmin;
    width: auto;
    max-width: 31vmin;
  }

  &__bottom {
    height: 2rem;
  }

  @media (max-width: 890px) {
    width: 70vmin;
    height: 70vmin;

    &__city {
      font-size: 10px;
      font-size: 3vmin;
    }

    &__title {
      font-size: 12px;
      font-size: 4vmin;
    }
    &__img {
      height: auto;
      max-height: 14vmin;
      width: auto;
      max-width: 46vmin;
    }
  }
}

.circle-int,
.circle-ext {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  animation: reveallight 300ms;
}

.circle-ext {
  background-color: $transparent-light-color;
  width: 58vmin;
  height: 58vmin;
  min-width: 15.625rem;
  min-height: 15.625rem;

  @media (max-width: 890px) {
    width: 80vmin;
    height: 80vmin;
  }
}
