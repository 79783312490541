$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}




















































































































































































.documents {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;

  @media (min-width: $breakpoint-medium) {
    height: 100%;
    object-fit: contain;
    padding: 0 3rem 3rem;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 3rem 3rem;
  }

  &__title {
    @extend %ext-title;
  }

  &__subtitle {
    @extend %ext-subTitle;
    color: var(--second-option-color);
    padding-bottom: 1rem;
  }

  &__text {
    @extend %ext-text;
  }

  &__content {
    @media (min-width: $breakpoint-large) {
      display: flex;
    }
    &--empty {
      margin-bottom: 2rem;
    }
  }

  &__list {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }
    padding: 0.4rem 1rem;
    min-height: 20rem;

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: $bg-color;
      -webkit-box-shadow: 0 0 1px $bg-color;
    }
  }
  &__list :first-child {
    border-top: none;
  }

  &__news,
  &__link {
    @media (hover: hover) {
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__link {
    color: var(--second-option-color)
  }
}

.documents-files,
.documents-timeline {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-large) {
    width: 50%;
  }
}

.documents-files {
  margin-right: 1rem;
}

.lot-selector {
  display: flex;
  align-items: center;

  &__label {
    margin-right: 0.4rem;
  }

  &__select {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 0 0.6rem;
    text-transform: uppercase;

    @media (hover: hover) {
      &:hover {
        background-color: $bg-color;
        cursor: pointer;
      }
    }
  }
}
