$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}
















.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}
.spinner {
  margin: auto;
  width: 100px;
  height: 100px;
  position: relative;
}

.cube1, .cube2 {
  background-color: #F0F0F0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.95s;
  animation-delay: -0.95s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(75px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(75px) translateY(75px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(75px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(75px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(75px) rotate(-90deg) scale(0.5);
  } 50% {
    transform: translateX(75px) translateY(75px) rotate(-179deg);
    -webkit-transform: translateX(75px) translateY(75px) rotate(-179deg);
  } 50.1% {
    transform: translateX(75px) translateY(75px) rotate(-180deg);
    -webkit-transform: translateX(75px) translateY(75px) rotate(-180deg);
  } 75% {
    transform: translateX(0px) translateY(75px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(75px) rotate(-270deg) scale(0.5);
  } 100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
