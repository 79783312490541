$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}









































































































































































































































































































































































































.validation {
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: $breakpoint-small) {
    padding-top: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.project {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-small) {
      max-width: 33%;
  }

  &__title {
    color: $main-dark-color;
    font-weight: bolder;
  }
}

.location {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  &__title {
    margin-right: 2rem;
    color: $main-dark-color;
    font-weight: bolder;
  }
}

input {
  border: none;
  border-bottom: 1px solid $main-medium-color;
  color: $main-dark-color;
  font-size: $fs-text;
  padding: 2px 0;

  &[type="text"]::placeholder,
  &[type="email"]::placeholder {
    border: solid $main-dark-color 1px;
    color: $main-medium-color;
    border: 0;
    font-weight: lighter;
  }

  &[type="radio"] + label {
    color: $main-medium-color;
  }

  &[type="radio"]:checked + label {
    font-weight: 600;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

textarea {
  font-weight: bold;
  outline: none;
}
.subWrapper {
  padding-bottom: 1.6rem;
  margin-bottom: 4px;

  @media screen and (max-width: 890px) {
    padding-bottom: 1.2rem;
  }
}

.wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper > * {
  width: 27%;

  @media screen and (max-width: 890px) {
    margin: 0 0;
  }
}

.wrapper,
.form__field-text {
  display: flex;
}

.form {
  margin-top: 2rem;
  width: 100%;
  animation: revealSlidelight 400ms;

  &__field-radio {
    width: 250px;
  }

  &__field-location {
    width: auto;
  }

  &__field-location > * {
    padding: 0 1rem 0 0.5rem;
  }

  &__field-radio > * {
    padding: 0 1rem 0 0.5rem;
  }

  &__field-text {
    flex-direction: column;
    position: relative;
  }

  &__field-text > label {
    color: $main-medium-color;
    font-size: $fs-text;
    position: absolute;
    top: -1rem;
  }
}

@media screen and (max-width: 892px) and (orientation: portrait) {
  .wrapper {
    display: block;
  }

  .wrapper > * {
    width: 100%;
    margin: 1rem 0;
  }
}

.form__field-text > label {
  animation: revealSlidelight 400ms;
}
