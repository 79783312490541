$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}




























































































































































.confirmation {
  margin: auto;
}

.contactFormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  background-color: $main-light-color;
  position: relative;

  @media (min-width: $breakpoint-small) {
    margin: auto;
    display: flex;
  }
  @media (min-width: $breakpoint-large) {
    width: 84%;
    height: 84%;
    margin: auto;
  }
}

.contactForm {
  width: 100%;
  margin: auto;
  border-radius: $border-radius;
  padding: 2rem 10%;
  background-color: $main-light-color;
  animation: revealSlidelight 500ms;
  font-size: $fs-text;

  &-succes {
    width: auto;
    margin: auto;
    border-radius: $border-radius;
    padding: 2rem 10%;
    background-color: $main-light-color;
    animation: revealSlidelight 500ms;
    font-size: $fs-text;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 1rem;
  }

  &__subTitle {
    animation: revealSlidelight 400ms;
  }
}

.validation {
  padding-top: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: $breakpoint-large) {
    padding-top: 0.8rem;
  }
}

.datepicker,
.daypicker {
  margin: 2rem 0;
  width: 48%;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.picker {
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    display: block;
  }
}

.daypicker {
  border: solid #D9DADBFF 1px;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;

  &__hour {
    text-align: center;
    width: 20%;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fs-text;
      font-weight: 500;
      width: 2rem;
      height: 2rem;
      margin: auto;
      border-radius: 50%;

      &:hover {
        background-color: rgb(229, 229, 229);
        cursor: pointer;
      }
    }
  }
  &__title {
    position: absolute;
    top: 1rem;
    font-weight: 600;
    font-size: $fs-text;
    color: $main-dark-color;
  }

  @media (max-width: 650px) {
    min-height: 220px;
  }
}

.hour-selected {
  div {
    background-color: #3685cf;
    color: white;

    &:hover {
      background-color: #3685cf;
    }
  }
}
