$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}




















































































































































































































.program {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;

  &-main {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 1rem;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 0 3rem 3rem;
  }

  @media (min-width: $breakpoint-large) {
    height: 100%;
  }

  &-title {
    @extend %ext-title;
  }

  &-tabs {
    display: flex;
    @extend %ext-tab;
    width: fit-content;
    position: relative;
    padding-bottom: 1rem;

    &__item {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      border-bottom: solid $border-color 2px;
      text-transform: capitalize;
      transition: border-bottom 200ms;

      &:hover {
        border-bottom: solid var(--second-option-color) 2px;
        cursor: pointer;
      }

      &--selected {
        border-bottom: solid var(--second-option-color) 2px;
        color: var(--second-option-color);
        font-weight: 700;

        &:hover {
          cursor: default;
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &-infos {
    &__adress {
      font-size: $fs-subTitle;
      color: var(--second-option-color);
      padding-bottom: 1rem;
    }

    &___desc {
      @extend %ext-text;
      margin-bottom: 1rem;
    }

    &__boxes {
      > p {
        display: inline-block;
        border: solid $main-dark-color 1px;
        padding: 2px 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: 700;
      }
    }

    &__actions {
      margin-top: 2rem;
    }

    &__desc {
      margin: 2rem;
      color: $main-medium-color;
    }

    &__link {
      text-decoration: underline;
      margin-bottom: 1rem;

      &:hover {
        color: var(--second-option-color);
        cursor: pointer;
      }
    }
    &__cta-visit,
    &__cta-infos {
      min-width: 20rem;;
    }

    &__cta-visit:after {
      content: "";
      display: block;
      background: url("/icon/icon-forward-white.png");
      background-size: 0.8rem auto;
      background-repeat: no-repeat;
      height: 1.2rem;
      width: 1rem;
      float: right;
      margin: 0.1rem 0.8rem;
    }

    &__cta-image {
      height: 0.9rem;
      width: auto;
      padding-left: 1rem;
    }
  }
  &-localisation,
  &-visuals {
    text-align: left;
    display: flex;
    align-items: center;

    @media (min-width: $breakpoint-medium) {
      height: 80%;
    }
  }
  &-localisation {
    margin: auto;
    width: 100%;
  }
}
