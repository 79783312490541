$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}














































































































































































































































































.lot-infos-container {
  animation: reveallight 220ms linear;

  @media (min-width: $breakpoint-large) {
    display: flex;
  }
}

.lot {
  padding: min(2rem, 6%);
  overflow-x: auto;
  animation: reveallight 600ms;

  &-title {
    @extend %ext-title;
  }

  &-tabs {
    display: flex;
    @extend %ext-tab;
    width: fit-content;
    position: relative;
    padding-bottom: 1rem;

    &__item {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      border-bottom: solid $border-color 2px;
      text-transform: capitalize;
      transition: border-bottom 200ms;

      &:hover {
        border-bottom: solid var(--second-option-color) 2px;
        cursor: pointer;
      }

      &--selected {
        border-bottom: solid var(--second-option-color) 2px;
        color: var(--second-option-color);
        font-weight: 700;

        &:hover {
          cursor: default;
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &-infos {
    &__specs,
    &__surfaces-subtitle,
    &__timeline {
      @extend %ext-subTitle;
    }

    @media (min-width: $breakpoint-medium) {
      height: 80%;
    }
  }

  @media (min-width: $breakpoint-medium) {
    background-color: $main-light-color;
    padding: 0 3rem 3rem;
  }

  @media (min-width: $breakpoint-large) {
    height: 100%;
  }
}

.lot-main-container {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;
  min-height: 40vh;

  &__tabs {
    display: flex;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    border: solid $border-color 1px;
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 0;
    text-align: center;
    font-weight: 700;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &__surfaces-text {
      padding-bottom: 1rem;
      color: $main-medium-color;
      display: inline-block;
      margin-right: 2rem;
    }

    &__text {
      color: $main-medium-color;
    }

    &--disabled {
      opacity: 0.6;
      background-color: $bg-color;
    }
  }

  &__lotNumber {
    color: var(--second-option-color);
  }
}

.lot-infos {
  &__aside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;

    @media (min-width: $breakpoint-large) {
      width: 40%;
    }
  }

  &__main {
    width: 60%;
    display: flex;
  }

  &__specs,
  &__surfaces-subtitle,
  &__timeline {
    @extend %ext-subTitle;
  }

  &__description {
    @extend %ext-text;
  }

  &__boxes {
    padding-bottom: 2rem;
  }

  &__surfaces-text {
    padding-bottom: 1rem;
    color: $main-medium-color;
    display: inline-block;
    margin-right: 2rem;
  }

  &-cta {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    &-image {
      height: 0.9rem;
      width: auto;
      padding-left: 1rem;
    }
  }

  &__gallery--mobile {
    width: 100%;
    min-height: 30vh;
    margin: auto;

    @media (min-width: $breakpoint-large) {
      display: none;
    }
  }

  &__gallery--desktop {
    width: 50%;
    margin: auto;

    @media (max-width: $breakpoint-large) {
      display: none;
    }
  }
}

.aside-main {
  max-height: 32vh;
  overflow: auto;

  &__card {
    margin-bottom: 1rem;
  }

  &__title,
  &__specs,
  &__surfaces,
  &__timeline {
    @extend %ext-subTitle;
  }

  &__description {
    @extend %ext-text;
  }

  &__text {
    color: $main-medium-color;
    display: inline-block;
    margin-right: 3rem;
  }

  @media (max-width: $breakpoint-large) {
    max-height: 50vh;
  }
}

.label-infos {
  display: inline-block;
  border: solid $main-medium-color 1px;
  padding: 2px 0.5rem;
  margin-right: 2px;
  margin-bottom: 4px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;

  &--count {
    text-transform: inherit;
  }
}

.infos-list {
  width: 100%;
  margin-right: 8rem;
  color: $main-medium-color;

  &__items {
    padding: 2px 0.5rem;
    list-style-type: disc;
    margin-left: 2rem;
  }
}

.surfaces-wrapper {

  &__heading {
    font-weight: 700;
    color: var(--second-option-color);
    margin-bottom: 1rem;
  }
  &__items {
    margin-top: 1rem;
    list-style-type: disc;
  }

  @media (min-width: $breakpoint-large) {
    width: 100%;
    display: flex;
  }
}
