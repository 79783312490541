$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}





























.projects {
  padding: min(2rem, 6%);
  background-color: $main-light-color;
  animation: reveallight 600ms;

  &__title {
    @extend %ext-title;
  }

  @media (min-width: $breakpoint-medium) {
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 3rem 3rem;
  }
}
.card-container {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-bottom: 0.5rem;
    @media (min-width: $breakpoint-small) {
        margin-right: 0.5rem;
    }
  }
}

.project-card {
  border: solid $border-color 1px;
  padding: 1.4rem;
  border-radius: $border-radius;
  width: 92%;
  margin: auto;
  background-color: $bg-color;

  &__cta {
    font-size: 0.7rem;
    border: solid black 1px;
    border-radius: $border-radius;
    padding: 0.3rem 0.4rem;
    background-color: var(--main-option-color);
    color: $main-light-color;
    text-transform: uppercase;
    font-weight: 700;
    transition: background-color 200ms, border 200ms, color 200ms;

    &--ghost {
      background-color: $bg-color;
      color: $main-dark-color;
    }

    &:hover {
      background-color: var(--second-option-color);
      border: solid var(--second-option-color) 1px;
      color: $main-light-color;
      cursor: pointer;
    }
  }

  @media (min-width: $breakpoint-small) {
    margin: 0;
    width: 22rem;
  }
}
