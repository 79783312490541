$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}






























































































.deleteProject {
  border-radius: $border-radius;
  background-color: $main-light-color;
  margin: auto;
  padding: 24px;
  position: relative;
  animation: revealSlidelight 400ms;
  margin: auto 1rem;
  font-size: $fs-text;

  @media (min-width: $breakpoint-small) {
    margin: auto;
  }

  &__cta {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    button:nth-child(1) {
      margin-right: 0;

      @media (min-width: $breakpoint-small) {
        margin-right: 1rem;
      }
    }

    button {
      width: 100%;

      @media (min-width: $breakpoint-small) {
        width: 14rem;
      }
    }
  }

  &__title,
  &__message {
    text-align: center;
  }
}

.deleteProject--confirmation {
  width: auto;
  margin: auto;
}
