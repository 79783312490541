$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}



































































.visit-link {
  position: relative;
  background-position: center;
  background-size: cover;
  font-weight: 700;
  border-radius: 0;
  transition: font-size 699ms;
  width: 100%;

  @media (min-width: $breakpoint-large) {
    background-color: #ffffff00;
  }
}

.circle-int,
.circle-ext {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.circle-ext {
  background-color: #ffffff63;
  width: 130px;
  height: 130px;

  @media (min-width: $breakpoint-medium) {
    width: 18vw;
    height: 18vw;
    min-height: 12.9rem;
    min-width: 12.9rem;
    max-width: 28vmin;
    max-height: 28vmin;
  }
}

.circle-int {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 116px;
  background-color: #ffffffb7;
  color: $main-dark-color;
  border: solid #ffffffb7 0px;
  transition: background-color 200ms 50ms, box-shadow 200ms 50ms, transform 400ms;

  @media (min-width: $breakpoint-medium) {
    width: 16vw;
    height: 16vw;
    min-height: 11rem;
    min-width: 11rem;
    max-width: 24vmin;
    max-height: 24vmin;
  }

  &__text {
    max-width: 10rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;

    @media (min-width: $breakpoint-large) {
      font-size: $fs-text;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.06);

    @media (min-width: $breakpoint-large) {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: none;
      transform: scale(1.06);
    }
  }
}
