//Fix google pegman
.gm-svpc div img {
  position: relative !important;
}

img {
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
}

.main-wrapper {
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-container {
  @media (min-width: $breakpoint-large) {
    display: flex;
    min-height: calc(100% - $header-size);
    height: calc(var(--vh) - $header-size);
    max-height: calc(var(--vh) - $header-size);
  }
}

.main-content {
  min-height: calc(100% - $header-size);
  width: 100%;
  flex-grow: 1;

  @media (min-width: $breakpoint-large) {
    width: 80%;
    min-height: calc(100% - $header-size);
    max-height: 100%;
    overflow-y: auto;
  }
}

.main-notifications {
  text-align: right;
  padding: 0.4rem 1rem;
}

.anim-revealLight {
  animation: reveallight 220ms linear;
}

.cta {
  padding: 0.6rem 2rem;
  margin: 2px;
  min-width: 14rem;
  border: 0;
  border-radius: $border-radius;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color linear 100ms;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--main-option-color);
  color: $main-light-color;

  &:hover {
    background-color: var(--second-option-color);
    cursor: pointer;
  }
}

.cta-small {
  min-width: 7.5rem;
  padding: 0.6rem 2rem;
  margin: 4px;
  border-radius: $border-radius;
  font-size: 0.8rem;
  font-weight: bold;
  transition: background-color 100ms;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--main-option-color);
  color: $main-light-color;

  &:hover {
    background-color: var(--second-option-color);
    cursor: pointer;
  }

  &--ghost {
    background-color: transparent;
    border: solid $main-dark-color 1px;
    transition: background-color 100ms;
    color: $main-dark-color;

    &:hover {
      background-color: var(--main-option-color);
      color: $main-light-color;
      cursor: pointer;
    }
  }
}

.cta--colorInversed {
  background-color: var(--second-option-color);
  color: $main-light-color;
  &:hover {
    background-color: var(--main-option-color);
  }
}

.cta--colorInversed {
  background-color: $main-light-color;
  color: $main-light-color;
  &:hover {
    background-color: var(--main-option-color);
  }
}

.cta--light {
  background-color: var(--main-option-color);
  color: $main-light-color;
  padding: 0.6em 3em;
  margin-top: 2rem;
  border: 0;
  border-radius: $border-radius;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  transition: background-color 100ms;

  &:hover {
    background-color: var(--second-option-color);
  }
}

.cta--disabled {
  background-color: grey;

  @media (hover: hover) {
    &:hover {
      cursor: default;
      background-color: grey;
    }
  }
}

.cta--option {
  background-color: $main-light-color;
  color: var(--main-option-color);
  border: solid var(--main-option-color) 1px;
  margin-top: 0;
  &:hover {
    background-color: var(--main-option-color);
    color: $main-light-color;
  }
}

.error-alert {
  color: $alert-color;
  font-size: $fs-small;
  margin-bottom: 4px;

  &--center {
    padding-top: 1rem;
    text-align: center;
  }
}

.cta-alert {
  &--inprogress,
  &--succes {
    width: 14rem;
    background-color: $main-light-color;
    color: var(--main-option-color);
    border: solid var(--main-option-color) 1px;

    &:hover {
      background-color: $main-light-color;
      color: var(--main-option-color);
      border: solid var(--main-option-color) 1px;
    }
  }

  &--inprogress {
    p {
      animation: fadeInFadeOut 1400ms infinite;
    }
  }
}

.terms {
  margin-top: 1rem;
  color: $main-medium-color;
  line-height: 1.2rem;

  input {
    margin: 0px 1rem 0 0;
  }

  a,
  label {
    font-size: 0.9rem;
  }

  a {
    text-decoration: underline;
    color: var(--main-option-color);
    cursor: pointer;

    &:hover {
      color: var(--second-option-color);
    }
  }
}

.timeline {
  margin-left: 0.5rem;

  &__item {
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;
    z-index: 5;
  }

  &__item:last-child {
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  &__box {
    border-left: solid black 1px;
  }

  &__heading {
    font-weight: 600;
  }

  &__subtitle {
    color: $main-medium-color;
  }
}

.timeline__item::before {
  content: " ";
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  border: solid $main-medium-color 1px;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: white;
  transform: translateX(-0.75rem);
  z-index: 1;
}

.timeline__item--checked::before {
  content: "";
  background-image: url("/icon/icon-check-cross-black.png");
  background-size: contain;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  border: solid $main-dark-color 1px;
  border-radius: 50%;
  margin-right: 0.5rem;
  transform: translateX(-0.75rem);
  z-index: 1;
}

.modal-container {
  z-index: $z-index-modal;
  background-color: $modal-bg-color;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  animation: reveallight 400ms;
  overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.captcha {
  color: $main-medium-color;
  border: solid #80808082 1px;
  border-radius: $border-radius;
  padding: 0.2rem 0.5rem;
  text-align: center;
  left: 0%;

  @media (min-width: $breakpoint-small) {
    position: absolute;
  }

  label {
    font-weight: 700;
  }
}

.captcha__result {
  width: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.datepicker .vc-title,
.datepicker .vc-weekday,
.datepicker .vc-day-content {
  font-size: $fs-text !important;
}

.datepicker .vc-day {
  min-height: 1rem;
}

.notifications {
  background-color: $main-light-color;
  color: $main-dark-color;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.contact-card-container {
  margin: 1rem auto;
  padding: 0.4rem 0.4rem 0 0.4rem;

  @media (min-width: $breakpoint-medium) {
    width: 100%;
    margin: 0 0 0 auto;
  }
}

.cookies-link-container {
  display: flex;
  height: 100%;

  &__link {
    font-size: 0.7rem;
    padding: 1rem 1rem 0.5rem;
    margin-top: auto;

    &--mobile {
      padding: 1rem 0;
    }

    &--absolute {
      position: absolute;
      bottom: 0.5rem;
      left: 1rem;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__link,
  &__link--absolute {
    text-transform: uppercase;

    &:before {
      content: " ";
      display: block;
      font-size: 1rem;
      background-image: url("/icon/icon-cookies.png");
      background-size: contain;
      background-repeat: no-repeat;
      float: left;
      width: 1.1rem;
      height: 1.1rem;
      min-width: 1.1rem;
      min-height: 1.1rem;
      margin-right: 0.5rem;
      z-index: 1;
    }
  }
}
