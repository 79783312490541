$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}















































































































.indexContainer {
  width: 100%;
  height: 100%;
  background-color: $main-light-color;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    margin: auto;
    width: 84%;
    height: 84%;
  }
  @media (min-width: $breakpoint-large) {
    margin: auto;
  }
}

.contactMenu {
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: $breakpoint-medium) {
    display: flex;
  }
}

.infoBox,
.appointmentBox {
  width: 100%;
  height: 50%;

  @media (min-width: $breakpoint-medium) {
    width: 50%;
    min-height: 100%;
  }
}

.infoBox {
  background-color: #e9e9e9e7;
}

.appointmentBox {
  background-color: #ffffffec;
}

.appointmentBox,
.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    color: $main-dark-color;
    font-weight: 900;
    margin: 0.4rem 0;
    font-size: 1.6rem;
    letter-spacing: -0.02em;
  }
}

.buttonBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem;
}

.contactConfirmation {
  background-color: $main-light-color;
  width: 100%;
  display: flex;
  &__text {
    margin: auto;
    color: $main-dark-color;
    font-weight: bolder;
    font-size: 1.6rem;
    text-transform: uppercase;
  }
}
