@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/new/opensans-regular-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-regular-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/new/opensans-extrabold-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-extrabold-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/new/opensans-light-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-light-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/new/opensans-semibold-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-semibold-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/new/opensans-bold-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-bold-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/new/opensans-extrabold-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-extrabold-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/new/opensans-italic-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-italic-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/new/opensans-extrabolditalic-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-extrabolditalic-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/new/opensans-lightitalic-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-lightitalic-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: italic;
  font-weight: 800;
  src: url("/fonts/new/opensans-extrabolditalic-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-extrabolditalic-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: italic;
  font-weight: 600;
  src: url("/fonts/new/opensans-semibolditalic-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-semibolditalic-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open sans";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/new/opensans-bolditalic-webfont.woff2") format("woff2"),
    url("/fonts/new/opensans-bolditalic-webfont.woff") format("woff");
  font-display: swap;
}
